<template>
	<div style="overflow: hidden;width: 100%;">
		<div :style="{ height: clientHeight + 'px' }" style="width: 100%;display: flex;">
			<div @mouseover="levelShow = true" :class="!levelShow ? 'hide' : ''" class="left-level-1" style="overflow: auto; overflow-x: hidden">
				<div class="home_logo">
					<img :class="!levelShow ? 'img_hide' : ''" src="./../../static/logo3.png" alt="" v-if="$store.state.img_show !== 'newLogin'" />
					<img :class="!levelShow ? 'newimg_hide' : ''" style="width: 40%" src="./../../static/newLoginLogo.png" alt="" v-else />
				</div>

				<!-- <ul>
          <li style="position: relative;" @click="homeClick">
            <div @click="$router.push({ path: '/index' }), menuLevel1Init()">
              <i :class="'iconfont iconshouye'"
                  style="display: block;"
                 @click="levelShow = true"></i>
              <span>首页</span>
            </div>
          </li>
        </ul>
        <ul>
          <li :key="k1"
              @click.stop="treeClick(level1, 0, k1 + '')"
              v-for="(level1, k1) in treeList"
              :class="level1.chhildShow ? 'in_avtive' : ''">
            <div :class="level1.chhildShow ? 'active' : ''">
              <i :class="level1.icon"></i>
              <span>{{ level1.name }}</span>
            </div>
            <span class="avtive_tiv" v-show="level1.chhildShow">
              <span class="avtive_top"></span>
              <span class="avtive_top_bot"></span>
              <span class="avtive_top_right"></span>
              <span class="avtive_top_bot_right"></span>
            </span>
          </li>
        </ul> -->

				<ul>
					<li :key="k1" @click.stop="treeClick(level1, 0, k1 + '')" v-for="(level1, k1) in treeList">
						<div :class="level1.chhildShow ? 'active' : ''" style="">
							<i :class="level1.icon"></i>
							<span v-show="levelShow">{{ level1.name }}</span>
							<i
								v-if="level1.children"
								v-show="levelShow"
								:style="{
									transform: level1.chhildShow ? 'rotate(90deg)' : 'rotate(0deg)',
								}"
								class="el-icon-arrow-right"
							></i>
							<i v-else class="zw_tive" v-show="levelShow" style="color: #fff"></i>
							<!-- <i style="font-size: 10px;" class="iconfont iconjiantou"></i> -->
							<!-- <i class="el-icon-caret-left"></i> -->

							<span class="avtive_tiv" v-show="level1.chhildShow">
								<span class="avtive_top"></span>
								<span class="avtive_top_bot"></span>
								<span class="avtive_top_right"></span>
								<span class="avtive_top_bot_right"></span>
							</span>
						</div>
						<ul
							v-show="level1.chhildShow && levelShow"
							:style="{
								height: !level1.chhildShow ? '0' : '',
							}"
							class="level-2"
						>
							<li @click.stop="treeClick(level2, level1, k1 + '' + k2)" v-for="(level2, k2) in level1.children" :key="k2">
								<div
									:style="{
										background: treeListIndex == k1 + '' + k2 ? '#ffffff2e' : '',
									}"
								>
									<span
										:style="{
											color: treeListIndex == k1 + '' + k2 ? '#fff' : '',
										}"
										v-show="levelShow"
										>{{ level2.name }}</span
									>
									<!-- <i :style="{
                      transform: level2.chhildShow
                        ? 'rotate(90deg)'
                        : 'rotate(0deg)',
                      color: treeListIndex == k1 + '' + k2 ? '#fff' : '',
                    }"
                     class="el-icon-arrow-right"
                     v-show="level2.children"></i> -->
									<!--                                     <i class="el-icon-arrow-right" style="opacity:0;"></i>
                                     -->
									<!-- <i class="el-icon-caret-left"></i> -->
								</div>

								<!-- <ul :style="{ height: !level2.chhildShow ? '0' : '' }"
                    class="level-3">
                  <li @click.stop="treeClick(level3, level2, k1 + '' + k2 + k3)"
                      v-for="(level3, k3) in level2.children">
                    <div :class="
                        treeListIndex == k1 + '' + k2 + k3 ? 'active' : ''
                      ">
                      <span v-show="levelShow">{{ level3.name }}</span>
                    </div>
                  </li>
                </ul> -->
							</li>
						</ul>
					</li>
				</ul>

				<div class="ss_icon">
					<i class="iconfont iconxingzhuang" @click="levelShow = false"></i>
				</div>
			</div>
			<div :class="levelShow ? 'hide' : ''" class="content clearfix" style="box-sizing: border-box; flex: 1;" :style="{ background: '#F4F6F9' }">
				<div class="clearfix headBreadcrumb" :style="{ background: '#fff' }" style="background: #f4f6f9; box-sizing: border-box">
					<!-- <span>
            数字化体能训练管理系统
          </span> -->
					<p v-if="$store.state.img_show == '科学化训练管理系统'">运动员科学化训练管理系统<span> Athlete Scientific Training Management System</span></p>
					<p v-else-if="$store.state.img_show == '宁夏'">宁夏回族自治区体育运动训练管理中心-科学训练管理系统</p>
					<p v-else-if="$store.state.img_show == '智慧化训练管理平台'">智慧化训练管理平台</p>
					<p v-else-if="$store.state.img_show == '上海竞训'">体能管理</p>
					<p v-else>数字化体能训练管理系统<span> Digital Physical Training Management System</span></p>
					<!-- separator-class="el-icon-arrow-right clearfix" -->
					<!-- <i class="el-icon-caret-right" style="margin-left: 20px;font-size: 16px;color: #999999;"></i>
          <el-breadcrumb class="top-breadcrumb"
                         style="float: left;"
                         separator="/">
            <el-breadcrumb-item style="padding:0 0 0 5px;">首页</el-breadcrumb-item>
            <el-breadcrumb-item :key="k"
                                v-for="(i, k) in $store.state.breadcrumbArr">{{ i }}
            </el-breadcrumb-item>
          </el-breadcrumb> -->

					<div class="login_name">
						<router-link target="_blank" to="/smartCockpit" v-if="smartType">
							<!-- <el-button type="text" v-if="smartType" @click="window.open('/smartCockpit', '_black')">驾驶舱</el-button> -->
							驾驶舱
						</router-link>

						<!-- <router-link target="_blank" :to="$store.state.img_show == '宁夏' ? '/VersionDescriptionNX' : '/VersionDescription'" style="color: #333">
							更新日志
						</router-link> -->

						<span>{{ $store.state.userData ? $store.state.userData.name : '管理员' }}，您好</span>
						<el-dropdown trigger="click" style="margin-left: 5px">
							<span class="el-dropdown-link">
								<i class="el-icon-caret-bottom" style="font-size: 16px"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item @click.native="loginOut"><i class="iconfont icon-tuichu-icon" style="margin-right: 6px"></i><span>退出</span> </el-dropdown-item>
								<el-dropdown-item @click.native="passEditor"><i class="iconfont icon-xiugaimima-icon" style="margin-right: 6px"></i><span>修改密码</span> </el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<!-- <div @click="routerGo"
               class="clearfix"
               style="float: right;margin: 0 40px 0 0;cursor:pointer;"
               v-show="returnShow">
            <i class="iconfont icon-fanhui-icon"
               style="color:#8F8F8F;"></i>
            <span style="color:#8F8F8F;">返回</span>
          </div>
          <div @click="routerGoList"
               class="clearfix"
               style="float: right;margin: 0 40px 0 0;cursor:pointer"
               v-show="train">
            <i class="iconfont icon-fanhui-icon"
               style="color:#8F8F8F;"></i>
            <span style="color:#8F8F8F;">返回</span>
          </div> -->
				</div>
				<div
					:style="{
						height: contentHeight + 'px',
						background: bg_grey ? 'rgb(244, 246, 249)' : '',
					}"
					class="contentViewWrap"
					style="overflow: auto; box-sizing: border-box; position: relative"
				>
					<!-- <ul class="level-2" v-show="home_click">
            <li v-for="(level2, k2) in childrenData.children" :key="k2" @click.stop="treeClick(level2, childrenData, level2.uuid)" :class="treeListIndex == level2.uuid ? 'avtive_epp' : ''">
              <div :class="treeListIndex == level2.uuid ? 'av_active' : ''">
                <span>{{ level2.name }}</span>
                <i :style="{
                    transform: level2.chhildShow
                      ? 'rotate(90deg)'
                      : 'rotate(0deg)',
                    color: treeListIndex == k2 + '' + level2.uuid ? '#0055E9' : '#000',
                    marginLeft: '10px'
                  }"
                    class="el-icon-arrow-right"
                    v-show="level2.children"></i>
              </div>
              <span class="avtive_tiv" v-show="treeListIndex == level2.uuid">
                <span class="avtive_top"></span>
                <span class="avtive_top_bot"></span>
                <span class="avtive_top_right"></span>
                <span class="avtive_top_bot_right"></span>
              </span>
              <ul class="level-3" :style="{ height: !level2.chhildShow ? '0' : '' }">
                <li @click.stop="treeClick(level3, level2, level3.uuid)"
                    v-for="(level3, k3) in level2.children" :key="k3" :class="treeListIndex == level3.uuid ? 'avtive_epp' : ''">
                  <div :class="treeListIndex == level3.uuid ? 'av_active' : ''">
                    <span>{{ level3.name }}</span>
                  </div>
                  <span class="avtive_tiv" v-show="treeListIndex == level3.uuid">
                    <span class="avtive_top"></span>
                    <span class="avtive_top_bot"></span>
                    <span class="avtive_top_right"></span>
                    <span class="avtive_top_bot_right"></span>
                  </span>
                </li>
              </ul>
            </li>
          </ul> -->
					<el-tabs v-show="yin_g" v-model="activeName" @tab-click="handleClick" v-if="childrenData.length" class="home_table">
						<el-tab-pane :label="item.name" :name="item.link" v-for="(item, index) in childrenData" :key="index"></el-tab-pane>
					</el-tabs>
					<router-view class="clearfix" style="flex: 1; margin: 0 10px; overflow: auto" v-loading.fullscreen.lock="$store.state.fullscreenLoading"> </router-view>
				</div>
			</div>
		</div>
		<el-dialog :close-on-click-modal="false" :visible.sync="passDialogTableVisible" title="修改密码" width="30%" center>
			<div class="ModifyDoalog">
				<!-- <p>修改密码</p> -->
				<el-form :label-position="'left'" :model="form" :rules="rules" label-width="0" ref="form">
					<el-form-item label="" prop="old_pwd">
						<el-input placeholder="旧密码" type="password" v-model="form.old_pwd"></el-input>
					</el-form-item>
					<el-form-item label="" prop="new_pwd">
						<el-input placeholder="新密码" type="password" v-model="form.new_pwd"></el-input>
					</el-form-item>
					<el-form-item label="" prop="affirm_pwd">
						<el-input placeholder="确认密码" type="password" v-model="form.affirm_pwd"></el-input>
					</el-form-item>
					<div style="text-align: center; padding: 10px 0 20px 0">
						<el-button @click.native="savePwd" style="margin: 0 20px 0 0" type="primary" v-no-more-click> 保存 </el-button>
						<el-button @click="passDialogTableVisible = false">取消</el-button>
					</div>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>
<script>
let data = {
	old_pwd: '',
	new_pwd: '',
	new_pwd: '',
}
export default {
	name: 'home',
	data() {
		return {
			smartType: false,
			yin_g: true,
			activeName: '',
			menuClickIndex: 0,
			passDialogTableVisible: false,
			clientHeight: document.body.clientHeight,
			contentHeight: -1,
			clientWidth: document.body.clientWidth,
			treeList: [],
			filterText: '',
			data: [],
			defaultProps: {
				children: 'children',
				label: 'name',
			},
			levelShow: true,
			breadcrumbArr: sessionStorage.getItem('breadcrumbArr') ? sessionStorage.getItem('breadcrumbArr').split(',') : [],
			treeListIndex: -1,
			level2Show: true,
			defaultChecked: [],
			defaultProjectChecked: [],
			level3IsShow: false,
			/*表单校验*/
			rules: {
				old_pwd: { ...this.$rules.noEmpty, message: '旧密码不能为空' },
				new_pwd: { ...this.$rules.noEmpty, message: '新密码不能为空' },
				affirm_pwd: { ...this.$rules.noEmpty, message: '请确认新密码' },
			},
			form: {
				...data,
			},
			childrenData: [],
			home_click: false,
			testUrlData: ['/textItem/bodyFunction', '/textItem/strengthTest', '/textItem/speedTest', '/textItem/sensibilityText', '/textItem/explosiveText', '/textItem/enduranceText'],
			controlData: ['/conditionModule/subjectiveState', '/conditionModule/sportsExpression', '/conditionModule/weightManagement', '/conditionModule/functionState'],
			treeData: [
				'/nutritionManagement/managementSon/TrainingNutrition',
				'/nutritionManagement/nourishmentSon/nourishmentList',
				'/nutritionManagement/managementSon/trainingDetails',
				'/nutritionManagement/nourishmentSon/dietaryDetails',
			],
			analysisData: ['/analysis/individualAnalysis', '/analysis/reportManagement'],
			trainingPlanData: ['/trainingPlan/weekly/teamPage', '/trainingPlan/stage/stagePage'],
			dataAccessData: ['/DataDocking/functionalMent', '/DataDocking/PhysicalMent'],
			deviceData: ['/management/deviceClassification', '/management/physClassification', '/management/funcEquipment', '/management/PhysEquipment'],
		}
	},
	components: {},
	computed: {
		level2IsShow() {
			let routeArr = [
				'/management/architecture',
				'/whereabouts/personaList',
				'whereabouts/teamList',
				'/week/DayPathList',
				'/function/functionlist',
				'/week/WeekPathList',
				'/week/MonthPathList',
				'/week/YearPathList',
				'/targetPlan/skill/dayPlanList',
				'/targetPlan/skill/weekPlanList',
				'/targetPlan/skill/monthPlanList',
				'/targetPlan/skill/yearPlanList',
				'/targetPlan/physical/dayPlanList',
				'/targetPlan/physical/weekPlanList',
				'/targetPlan/physical/monthPlanList',
				'/targetPlan/physical/yearPlanList',
				'/targetPlan/Intelligence/dayPlanList',
				'/targetPlan/Intelligence/weekPlanList',
				'/targetPlan/Intelligence/monthPlanList',
				'/targetPlan/Intelligence/yearPlanList',
				'/targetPlan/regenerate/dayPlanList',
				'/targetPlan/regenerate/weekPlanList',
				'/targetPlan/regenerate/monthPlanList',
				'/targetPlan/regenerate/yearPlanList',
				'/feedbackPath/feedbackList',
				'/feedbackPath/attendanceList',
				'/feedbackPath/effectList',
				'/whereabouts/teamList',
				'/train/homePage',
				'/train/homePage/dailyPlan',
				'/train/look_down',
				'/train/echarts/people',
				'/train/echarts/team',
				'/train/homePage/monthPlan',
				'/train/homePage/yearPlan',
				'/function/function_echarts',
				'/function/assessment_echarts',
			]
			let routerName = ['functionlist', 'reportTeam', 'reportPersonal', 'physicallist']
			if (routeArr.indexOf(this.$route.path) != -1) {
				return true
			} else if (routerName.indexOf(this.$route.name) != -1) {
				return true
			} else {
				return false
			}
		},
		level3Show() {
			let routeArr = ['/selectionSet/management', '/selectionSet/Individual', '']
			if (routeArr.indexOf(this.$route.path) != -1) {
				return true
			} else {
				return false
			}
		},
		train() {
			let routerName = [
				'trainGagePhysicals',
				'trainGageSkill',
				'trainGageIntelligence',
				'trainGageRecovery',
				'assessmentTestheadthForm',
				'assessmentTesenginery',
				'assessmentTesfitness',
				'assessmentTesfunction',
				'assessmentTesIntelligence',
				'assessmentTesrecovery',
				'assessmentTesskill',
			]
			if (routerName.indexOf(this.$route.name) != -1) {
				return true
			} else {
				return false
			}
		},
		returnShow() {
			let routeArr = [
				'/management/architectureOperation',
				'/function/planEntry',
				'/week/DayPathNewbuild',
				'/week/weekPathNewbuild',
				'/week/monthPathNewbuild',
				'/week/yearPathNewbuild',
				'/week/DayPathLook',
				'/week/weekPathLook',
				'/week/monthPathLook',
				'/week/yearPathLook',
				'/feedbackPath/feedbackNewBuild',
				'/feedbackPath/effectLook',
				'/targetPlan/skill/dayPlanNewbuild',
				'/targetPlan/skill/weekPlanNewbuild',
				'/targetPlan/skill/monthPlanNewbuild',
				'/targetPlan/skill/yearPlanNewbuild',
				'/targetPlan/skill/dayPlanLook',
				'/targetPlan/skill/weekPlanLook',
				'/targetPlan/skill/monthPlanLook',
				'/targetPlan/skill/yearPlanLook',
				'/targetPlan/physical/dayPlanNewbuild',
				'/targetPlan/physical/weekPlanNewbuild',
				'/targetPlan/physical/monthPlanNewbuild',
				'/targetPlan/physical/yearPlanNewbuild',
				'/targetPlan/physical/dayPlanLook',
				'/targetPlan/physical/weekPlanLook',
				'/targetPlan/physical/monthPlanLook',
				'/targetPlan/physical/yearPlanLook',
				'/targetPlan/Intelligence/dayPlanNewbuild',
				'/targetPlan/Intelligence/weekPlanNewbuild',
				'/targetPlan/Intelligence/monthPlanNewbuild',
				'/targetPlan/Intelligence/yearPlanNewbuild',
				'/targetPlan/Intelligence/dayPlanLook',
				'/targetPlan/Intelligence/weekPlanLook',
				'/targetPlan/Intelligence/monthPlanLook',
				'/targetPlan/Intelligence/yearPlanLook',
				'/targetPlan/regenerate/dayPlanNewbuild',
				'/targetPlan/regenerate/weekPlanNewbuild',
				'/targetPlan/regenerate/monthPlanNewbuild',
				'/targetPlan/regenerate/yearPlanNewbuild',
				'/targetPlan/regenerate/dayPlanLook',
				'/targetPlan/regenerate/weekPlanLook',
				'/targetPlan/regenerate/monthPlanLook',
				'/targetPlan/regenerate/yearPlanLook',
				'/feedbackPath/feedbackLook',
				'/management/funcEquipment',
				// '/DataDocking/EquipmentLook',
				// '/DataDocking/functionalMent',
			]
			let routerName = [
				'trainMonitorList',
				'presentationheadthForm',
				'planAdd',
				'planEntry',
				'planEnd',
				'analysisTeam',
				'personal',
				'teamView',
				'personalView',
				'trainGagePlanEnd',
				'physical_entry',
			]
			if (routeArr.indexOf(this.$route.path) != -1) {
				return true
			} else if (routerName.indexOf(this.$route.name) != -1) {
				return true
			} else if (this.$route.path.split('/')[2] == 'eRecord') {
				return true
			} else {
				return false
			}
		},
		bg_grey() {
			let routeArr = ['/index', '/index/noticeMor', '/train/look_down', '/train/echarts/people', '/train/echarts/team', '/function/function_echarts', '/function/assessment_echarts']
			if (routeArr.indexOf(this.$route.path) != -1) {
				return true
			} else {
				return false
			}
		},
		bg_white() {
			let routeArr = ['/train/look_down', '/train/echarts/people', '/train/echarts/team', '/function/function_echarts', '/function/assessment_echarts']
			if (routeArr.indexOf(this.$route.path) != -1) {
				return true
			} else {
				return false
			}
		},
		home_top() {
			return this.$route.path == '/index'
		},
	},

	watch: {
		filterText(val) {
			this.$refs.tree.filter(val)
		},
		$route(to, from) {
			if (to.path == '/textItem/textManagement' && this.testUrlData.indexOf(from.path) !== -1) {
				this.$router.push({ path: from.path, query: from.query })
				this.treeList.forEach(item => {
					if (item.name == '测试管理') {
						item.chhildShow = true
					}
				})
			} else if (to.path == '/conditionModule/stateRoom' && this.controlData.indexOf(from.path) !== -1) {
				this.$router.push({ path: from.path, query: from.query })
				this.treeList.forEach(item => {
					if (item.name == '状态监控') {
						item.chhildShow = true
					}
				})
			} else if (to.path == '/nutritionManagement/management' && this.treeData.indexOf(from.path) !== -1) {
				this.$router.push({ path: from.path, query: from.query })
				this.treeList.forEach(item => {
					if (item.name == '营养管理') {
						item.chhildShow = true
					}
				})
			} else if (to.path == '/analysis/statisticalAnalysis' && this.analysisData.indexOf(from.path) !== -1) {
				this.$router.push({ path: from.path, query: from.query })
				this.treeList.forEach(item => {
					if (item.name == '统计分析') {
						item.chhildShow = true
					}
				})
			} else if (to.path == '/trainingPlan/planPage' && this.trainingPlanData.indexOf(from.path) !== -1) {
				this.$router.push({ path: from.path, query: from.query })
				this.treeList.forEach(item => {
					if (item.name == '训练计划') {
						item.chhildShow = true
					}
				})
			} else if (to.path == '/DataDocking/dataAccess' && this.dataAccessData.indexOf(from.path) !== -1) {
				this.$router.push({ path: from.path, query: from.query })
				this.treeList.forEach(item => {
					if (item.name == '设备数据') {
						item.chhildShow = true
					}
				})
			} else if (to.path == '/management/device' && this.deviceData.indexOf(from.path) !== -1) {
				this.$router.push({ path: from.path, query: from.query })
				this.treeList.forEach(item => {
					if (item.name == '设备管理') {
						item.chhildShow = true
					}
				})
			}
			if (this.level3Show) {
				this.level3IsShow = true
			}
			this.menuLevel1Close()
			this.menuLevel1Init()
			this.menuLevel1InitButtons()

			this.$store.state.breadcrumbArr = this.$route.meta
			let index = 0
			if (this.$route.query.uuid == undefined && this.level2IsShow) {
				let time = setInterval(() => {
					index++
					if (index > 100) {
						clearInterval(time)
					}
					if (this.$store.state.treeMenu != null && this.$store.state.treeMenu.length != 0) {
						this.$store.state.department = JSON.stringify(this.$store.state.lastTreeMenu)
						// console.log(this.$store.state.lastTreeMenu.uuid)
						clearInterval(time)
						if (to.query.currentPage) {
							this.$router.push({
								path: `${this.$route.path}?uuid=${this.$store.state.lastTreeMenu.uuid}&currentPage=${to.query.currentPage}`,
							})
						} else {
							this.$router.push({
								path: `${this.$route.path}?uuid=${this.$store.state.lastTreeMenu.uuid}`,
							})
						}
					}
				}, 300)
			} else if (this.$route.query.uuid == undefined && this.level3Show) {
				let time = setInterval(() => {
					index++
					if (index > 200) {
						clearInterval(time)
					}
					if (this.$store.state.getProjectTree != null && this.$store.state.getProjectTree.length != 0) {
						clearInterval(time)
						this.$router.push({
							path: `${this.$route.path}?uuid=${this.$store.state.getProjectTree[0].uuid}`,
						})
					}
				}, 300)
			}
			this.menuLevel2Init()
			if (this.$route.query.sh != 'show') {
				this.yin_g = true
			} else {
				this.yin_g = false
			}
		},
	},

	created() {
		var cookies = document.cookie
		var pattern = new RegExp('_token=([^;]*)')
		var match = cookies.match(pattern)
		if (!match) {
			// this.$message({
			// 	type: 'error',
			// 	message: '请先登录',
			// })
			this.$router.push({ path: '/login' })
		}

		this.$axios.post('/p/login/getlogininfo').then(res => {
			sessionStorage.setItem('userData', JSON.stringify(res.data.data))
			this.$store.state.userData = res.data.data
		})

		this.$store.state.breadcrumbArr = this.$route.meta
		this.getTree()
		let index = 100
		// this.$store.state.fullscreenLoading = true;
		// setTimeout(() => {
		//     this.$store.state.fullscreenLoading = false;
		// }, 2000);
		if (this.$route.query.uuid == undefined && this.level2IsShow) {
			let time = setInterval(() => {
				index++
				if (index > 200) {
					clearInterval(time)
				}

				if (this.$store.state.treeMenu != null && this.$store.state.treeMenu.length != 0) {
					this.$store.state.department = JSON.stringify(this.$store.state.lastTreeMenu)
					clearInterval(time)
					this.$router.push({
						path: `${this.$route.path}?uuid=${this.$store.state.lastTreeMenu.uuid}`,
					})
				}
			}, 300)
		} else if (this.$route.query.uuid == undefined && this.level3Show) {
			let time = setInterval(() => {
				index++
				if (index > 200) {
					clearInterval(time)
				}
				if (this.$store.state.getProjectTree != null && this.$store.state.getProjectTree.length != 0) {
					clearInterval(time)
					this.$router.push({
						path: `${this.$route.path}?uuid=${this.$store.state.lastTreeMenu.uuid}`,
					})
				}
			}, 300)
		}
		setTimeout(() => {
			let path = this.$route.path
			if (this.testUrlData.indexOf(path) !== -1) {
				this.treeList.forEach(item => {
					if (item.name == '测试管理') {
						item.chhildShow = true
					}
				})
			} else if (this.controlData.indexOf(path) !== -1) {
				this.treeList.forEach(item => {
					if (item.name == '状态监控') {
						item.chhildShow = true
					}
				})
			} else if (this.treeData.indexOf(path) !== -1) {
				this.treeList.forEach(item => {
					if (item.name == '营养管理') {
						item.chhildShow = true
					}
				})
			} else if (this.analysisData.indexOf(path) !== -1) {
				this.treeList.forEach(item => {
					if (item.name == '个体分析') {
						item.chhildShow = true
					}
				})
			} else if (this.trainingPlanData.indexOf(path) !== -1) {
				this.treeList.forEach(item => {
					if (item.name == '训练计划') {
						item.chhildShow = true
					}
				})
			}
		}, 400)
	},
	mounted() {
		let that = this

		// 营养显示隐藏tab
		if (this.$route.query.sh != 'show') {
			this.yin_g = true
		} else {
			this.yin_g = false
		}
		function fnResize() {
			var deviceWidth = document.documentElement.clientWidth || window.innerWidth
			if (deviceWidth < 1100) {
				document.documentElement.style.fontSize = deviceWidth / 70 + 'px'
			} else {
				document.documentElement.style.fontSize = 'inherit'
			}
			that.clientHeight = document.body.clientHeight
			that.contentHeight = document.body.clientHeight - document.querySelector('.headBreadcrumb').clientHeight - 10
		}
		document.body.width = this.clientWidth + 'px'
		var winHeight = document.body.clientHeight

		window.onresize = () => {
			fnResize()
			this.$store.state.equipment = (document.documentElement.clientWidth || window.innerWidth) > 1100
			var thisHeight = document.body.clientHeight //窗体变化后的高度
			// alert(winHeight)
			// alert(thisHeight)
			if (winHeight - thisHeight > 50) {
				/*
                                    软键盘弹出
                                    50是设置的阈值，用来排除其他影响窗体大小变化的因素，比如有的浏览器的工具栏的显示和隐藏
                                    */
				// $('body').css('height', winHeight + 'px');
				document.body.style.height = '200%'
				// document.body.style.height = winHeight + 'px';
			} else {
				/*
                                    软键盘关闭
                                    */
				document.body.style.height = '100%'
				// $('body').css('height', '100%');
			}
		}
		document.body.addEventListener('click', function(e) {
			// 兼容性处理
			var event = e || window.event
			var target = event.target || event.srcElement
			// 判断是否匹配目标元素
			if (target.className === 'el-input__inner' && !that.$store.state.equipment) {
				let time = null
				time = setInterval(function() {
					target.scrollIntoView(false)
				}, 200)

				target.addEventListener('focus', () => {
					time = setInterval(function() {
						target.scrollIntoView(false)
					}, 200)
				})
				target.addEventListener('blur', () => {
					clearInterval(time)
				})
			}
		})
		// if (!this.$store.state.equipment) {
		//   this.levelShow = false;
		//   this.level2Show = false;
		// }

		this.$axios.post('/p/cockpit/authorization').then(res => {
			if (res.data.code == 0) {
				this.smartType = res.data.data
			}
		})
	},
	methods: {
		homeClick() {
			this.home_click = false
			this.menuLevel1Close()
		},
		routerGoList() {
			this.$router.push({ name: 'architecture' })
		},
		menuLevel1Close() {
			if (this.$route.path == '/index') {
				this.treeList.forEach((i, k1) => {
					i.chhildShow = false
					if (i.children) {
						i.children.forEach((j, k2) => {
							j.chhildShow = false
							if (j.children) {
								j.children.forEach((o, k3) => {
									o.chhildShow = false
								})
							}
						})
					}
				})
				this.treeListIndex = -1
			}
		},

		menuLevel2Close() {
			// this.treeList.forEach((i, k1) => {
			//   i.chhildShow = false;
			//   if (i.children) {
			//     i.children.forEach((j, k2) => {
			//       j.chhildShow = false;
			//       if (j.children) {
			//         j.children.forEach((o, k3) => {
			//           o.chhildShow = false;
			//         });
			//       }
			//     });
			//   }
			// });
			this.treeListIndex = -1
		},
		/*
                        一级菜单默认刷新默认展开
                    */
		menuLevel2Init() {
			this.home_click = false
			let path = this.$route.path
			this.treeList.forEach((i, k1) => {
				if (i.children) {
					i.children.forEach((j, k2) => {
						if (j.children) {
							j.children.forEach((o, k3) => {
								if (o.link == path) {
									this.home_click = true
								}
							})
						} else if (j.link == path) {
							this.home_click = true
						}
					})
				} else if (i.link == path) {
					this.home_click = true
				}
			})
			if (!this.home_click) {
				this.menuLevel2Close()
				// this.home_click = true
			}
		},

		handleClick(tab, event) {
			sessionStorage.removeItem('arch_search')
			this.$router.push(this.activeName)
		},

		menuLevel1Init(toPath) {
			let path = this.$route.path
			this.treeList.forEach((i, k1) => {
				if (i.children) {
					i.children.forEach((j, k2) => {
						if (j.children) {
							j.children.forEach((o, k3) => {
								if (o.link == path) {
									this.childrenData = j.children
									i.chhildShow = true
									j.chhildShow = true
									o.chhildShow = true
									// this.treeListIndex = o.uuid;
									this.treeListIndex = k1 + '' + k2
									this.home_click = true
									this.activeName = path
								}
							})
						} else if (j.link == path) {
							this.childrenData = []
							this.treeListIndex = k1 + '' + k2
							this.home_click = true
							// this.treeListIndex = j.uuid;
							i.chhildShow = true
							j.chhildShow = true
						}
					})
				} else if (i.link == path) {
					this.childrenData = []
					i.chhildShow = true
				}
			})
			this.$forceUpdate()
		},
		/*
        刷新默认获取权限
    */
		menuLevel1InitButtons() {
			let getButton = uuid => {
				if (!path) {
					return false
				}

				this.$axios
					.post(
						`/p/menu/getChildrenMenu`,
						this.$qs({
							uuid: uuid,
						})
					)
					.then(res => {
						if (res.data.code == 0) {
							let arr = [],
								lins = []
							for (let i in res.data.data) {
								arr.push(res.data.data[i].btn_type)
							}
							for (let i in res.data.data) {
								lins.push(res.data.data[i].name)
							}
							this.$store.commit('setPermissionsArr', arr)
							this.$store.commit('setPermissionsName', lins)
							this.$store.commit('setPermissionsData', res.data.data)
							this.setNounButton(path)
						}
					})
			}

			let path = this.$route.path

			let isUuid = true
			let nounType = false

			this.treeList.forEach((i, k1) => {
				if (i.children) {
					i.children.forEach((j, k2) => {
						if (j.children) {
							j.children.forEach((o, k3) => {
								if (o.link == path) {
									isUuid = false
									sessionStorage.setItem('getButtons', o.uuid)
									getButton(o.uuid)
								}
							})
						} else if (j.link == path) {
							isUuid = false
							sessionStorage.setItem('getButtons', j.uuid)
							getButton(j.uuid)
						}
					})
				} else if (i.link == path) {
					isUuid = false
					sessionStorage.setItem('getButtons', i.uuid)
					getButton(i.uuid)
				}
			})
			if (isUuid) {
				getButton(sessionStorage.getItem('getButtons'))
			}
		},
		setNounButton(path) {
			let uuid = ''
			if (this.testUrlData.indexOf(path) !== -1) {
				// console.log(this.$store.state.permissionsData)
				uuid = this.$store.getters.permissionsData(path)[0].uuid
			} else if (this.controlData.indexOf(path) !== -1) {
				uuid = this.$store.getters.permissionsData(path)[0].uuid
			}

			// else if(this.treeData.indexOf(path) !== -1) {
			//   uuid = this.$store.getters.permissionsData(path)[0].uuid
			// }
			else if (this.treeData.indexOf(path) !== -1 || this.analysisData.indexOf(path) !== -1) {
				uuid = this.$store.getters.permissionsData(path)[0].puuid
			} else if (
				path == '/nutritionSet/knowledgeAdd' ||
				path == '/nutritionSet/knowledgeList/knowledgeAddS' ||
				path == '/nutritionSet/nutritionLibraryAdd' ||
				path == '/nutritionSet/nutritionLibraryAddS'
			) {
				uuid = sessionStorage.getItem('getButtons')
				// console.log("z",uuid);
			} else if (this.trainingPlanData.indexOf(path) !== -1) {
				uuid = this.$store.getters.permissionsData(path)[0].uuid
			} else {
				return false
			}

			this.$axios
				.post(
					`/p/menu/getChildrenMenu`,
					this.$qs({
						uuid: uuid,
						sibling_flag: 1,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						let arr = [],
							lins = []
						for (let i in res.data.data) {
							arr.push(res.data.data[i].btn_type)
						}
						for (let i in res.data.data) {
							lins.push(res.data.data[i].name)
						}
						this.$store.commit('setPermissionsArr', arr)
						this.$store.commit('setPermissionsName', lins)
					}
				})
		},

		routerGo() {
			if (this.$route.path.split('/')[2] == 'eRecord') {
				this.$router.push({ path: '/management/architecture' })
			} else if (this.$route.path == '/function/testAdd') {
				this.$router.push({ path: '/function/testList' })
			} else if (this.$route.path == '/selectionSet/AsingleQuery') {
				//单次查询
				this.$router.push({ path: '/selectionSet/Individual' })
			} else if (this.$route.path == '/selectionSet/StageOfQuery') {
				//阶段查询
				this.$router.push({ path: '/selectionSet/Individual' })
			} else if (this.$route.path == '/function/planEnty' || this.$route.path == '/function/resultsInfo') {
				this.$confirm('是否离开本页，离开后可能数据未保存', '', {
					distinguishCancelAndClose: true,
					confirmButtonText: '是',
					center: true,
					cancelButtonText: '否',
				}).then(() => {
					this.$router.go(-1)
				})
			} else if (this.level2IsShow) {
				this.$router.go(-2)
			} else {
				this.$router.go(-1)
			}
		},
		loginOut() {
			this.$confirm('确定要退出吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
			}).then(() => {
				this.$axios
					.get('/p/login/logout')
					.then(res => {
						if (res.data.code == 0) {
							this.$utils.message({
								type: 'success',
								message: res.data.message,
							})
							document.cookie = '_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
							this.$router.push({ path: '/login' })
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {
						console.log(error)
					})
			})
		},
		getTree() {
			this.$axios.post('/p/menu/getNavigateMenu').then(res => {
				if (res.data.code == 0) {
					this.$store.state.menu = res.data.data
					this.treeList = res.data.data
					this.menuLevel1Init()
					this.menuLevel1InitButtons()
				}
			})
			this.$axios.get('/p/staff/tree').then(res => {
				if (res.data.code == 0) {
					this.defaultChecked = this.level2IsShow && this.$route.query.uuid ? [this.$route.query.uuid] : [res.data.data.department[0].uuid]
					this.$store.state.lastTreeMenu = res.data.data.department[0]
					// this.$refs.tree.setCurrentKey(res.data.data.department[0].uuid);
					// this.$store.commit('setTreeMenu', res.data.data.department);
					this.$store.state.treeMenu = res.data.data.department
					this.$store.state.treeTransferData = res.data.data.department
				}
			})
			// this.$axios.post("/p/project/getProjectTree").then((res) => {
			//     if (res.data.code == 0) {
			//         this.$store.state.getProjectTree = res.data.data;
			//         this.defaultProjectChecked = this.level2IsShow && this.$route.query.uuid ? [this.$route.query.uuid] : res.data.data[0] ? [res.data.data[0].uuid] : [];
			//     }
			// })
		},
		// treeClick (item, parent, index) {
		//   console.log(item, parent, index)
		//   this.home_click = true
		//   if(parent == 0) {
		//     this.childrenData = item
		//   }
		//   // debugger
		//   this.treeListIndex = index;
		//   if (item.level != 1 && item.children == undefined) {
		//     // this.$store.state.breadcrumbArr = [parent.name, item.name];
		//     // this.breadcrumbArr = [parent.name, item.name];
		//     // sessionStorage.setItem('breadcrumbArr', this.breadcrumbArr)
		//   } else if (item.level == 1) {
		//     this.treeList.forEach((i, k) => {
		//       if (i.name != item.name) {
		//         i.chhildShow = false;
		//       }
		//     });
		//   }
		//   if (item.link) {
		//     this.$router.push({ path: item.link });
		//     this.treeListIndex = index;
		//   } else if (item.children) {
		//     item.chhildShow = !item.chhildShow;
		//   }
		//   if (!this.$store.state.equipment) {
		//     this.menuClickIndex++;
		//     let index = this.menuClickIndex;
		//     setTimeout(() => {
		//       if (this.menuClickIndex == index) {
		//         this.levelShow = false;
		//       }
		//     }, 5000);
		//   }
		// },

		treeClick(item, parent, index) {
			sessionStorage.removeItem('arch_search')

			this.treeListIndex = index
			if (item.level != 1 && item.children == undefined) {
			} else if (item.level == 1) {
				this.treeList.forEach((i, k) => {
					if (i.name != item.name) {
						i.chhildShow = false
					}
				})
			}
			if (item.level == 2 && item.children) {
				this.activeName = item.children[0].link
				item.chhildShow = !item.chhildShow
				this.$router.push({ path: item.children[0].link })
			} else if (item.link) {
				item.chhildShow = !item.chhildShow
				this.$router.push({ path: item.link })
			} else if (item.children) {
				item.chhildShow = !item.chhildShow
			}
			if (!this.$store.state.equipment) {
				this.menuClickIndex++
				let index = this.menuClickIndex
				setTimeout(() => {
					if (this.menuClickIndex == index) {
						this.levelShow = false
					}
				}, 5000)
			}
		},

		filterNode(value, data) {
			if (!value) return true
			return data.label.indexOf(value) !== -1
		},
		treeNodeClick(node, data) {
			let el = document.querySelectorAll('.left-level-2 .el-tree-node.is-focusable.is-checked .el-tree-node__content')
			el.forEach((i, k) => {
				i.style.backgroundColor = 'transparent'
			})
			// console.log(node,data)
			this.$store.state.lastTreeMenu = node
			this.$store.state.department = sessionStorage.getItem('department')
			sessionStorage.setItem('department', JSON.stringify(node))
			if (!this.$store.state.equipment) {
				setTimeout(() => {
					this.level2Show = false
				}, 5000)
			}

			this.$router.push({ path: `${this.$route.path}?uuid=${node.uuid}` })
		},
		passEditor() {
			if (!this.$store.state.userData) {
				this.$utils.message({
					type: 'error',
					message: '请先登录',
				})
				this.$router.push({ path: '/login' })
			}
			this.passDialogTableVisible = true
			this.form = { ...data }
			setTimeout(() => {
				this.$refs['form'].clearValidate()
			}, 100)
		},
		savePwd() {
			let submit = () => {
				this.$axios
					.post(
						'/p/User/savePwd',
						this.$qs({
							...this.form,
							uuid: this.$store.state.userData.uuid,
						})
					)
					.then(res => {
						if (res.data.code == 0) {
							this.passDialogTableVisible = false
							this.$utils.message()
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
			}

			this.$refs['form'].validate(valid => {
				if (valid) {
					submit()
				} else {
					return false
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.box-head {
	width: 100%;
	height: 60px;
	background: #fff;
	padding: 12px 0 0 10px;
	box-sizing: border-box;
	box-shadow: 20px 2px 10px 0px rgba(219, 219, 219, 1);
	margin-bottom: 2px;
	box-sizing: border-box;

	.head-left {
		float: left;

		img {
			padding: 0px 0 0 0;
			float: left;
			width: 50px;
			// height: 46px;
			margin-top: -10px;
			margin-left: 8px;
			height: 50px;
		}

		a {
			font-size: 22px;
			font-weight: 400;
			color: rgba(54, 54, 54, 1);
			float: left;
			margin-left: 8px;
			margin-top: 4px;
		}
	}

	.head-right {
		float: right;

		img {
			width: 34px;
			height: 34px;
			border-radius: 50%;
			display: inline-block;
			border: 2px solid #fff;
		}

		div {
			height: 16px;
			border-right: 1px solid rgba(244, 246, 249, 1);
			float: left;
			width: 185px;
			margin-top: 10px;
			margin-right: 22px;

			img {
				float: left;
				margin-top: -12px;
			}

			span {
				float: left;
				margin-top: 10px;
				margin-left: 10px;
				margin-top: -2px;
				font-size: 16px;
				color: rgba(255, 255, 255, 1);
			}
		}

		// div:nth-child(1) {
		//     i {
		//         margin: 0;
		//     }

		//     width: 70px;
		// }

		div:nth-child(1) {
			// margin-left: 40px;
			// margin-right: 40px;
			float: right;

			i {
				float: left;
				font-size: 20px;
				margin: 0 0 0 6px;
				color: #c1c1c1;
			}

			span {
				color: #4b4b4b;
			}
		}

		i {
			float: left;
			color: #fff;
			margin: 10px 10px 0 30px;
			font-size: 16px;
		}

		& > span {
			float: left;
			margin: 8px 30px 0 0;
			color: #fff;
		}
	}

	.el-dropdown {
		width: 0 !important;
		float: left;
		margin-top: 0px !important;
	}
}

.home_logo {
	height: 90px;
	box-sizing: border-box;
	margin: 0 auto;
	padding-top: 20px;
	img {
		width: 49%;
		height: auto;
		display: block;
		margin: 0 auto;
	}
	.img_hide {
		width: 72%;
	}
	.newimg_hide {
		width: 60% !important;
	}
}

.left-level-1.hide {
	width: 110px !important;
}

.left-level-1 {
	// width: 11.5vw !important;
	font-family: 'Helvetica Neue', 'Helvetica', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
	color: #000;

	&::-webkit-scrollbar {
		width: 0 !important;
	}

	float: left;
	width: 190px;
	height: 100%;
	overflow-x: hidden;
	position: relative;
	// background: url("./../../static/home_bg.jpg") no-repeat bottom center #0055E9;
	background: #0055e9;

	> ul {
		margin-top: 30px;
	}

	ul {
		// width: 190px;
		// overflow: hidden;
		transition: all 0.5s;
		.in_avtive {
			position: relative;
		}

		li {
			color: #fff;
			// height: 46px;
			width: 100%;
			box-sizing: border-box;
			// padding: 12px 0;
			cursor: pointer;

			.avtive_tiv {
				position: absolute;
				top: 0px;
				right: 0;
				height: 90px;
				width: 1px;
				background: #fff;
				display: block;
				z-index: 9;
				.avtive_top {
					width: 25px;
					height: 15px;
					position: absolute;
					top: -15px;
					right: 0;
					z-index: 8;
					background: #0055e9;
					border-bottom-right-radius: 15px;
				}
				.avtive_top_bot {
					width: 25px;
					height: 15px;
					position: absolute;
					top: -15px;
					right: 0;
					background: #fff;
					z-index: 7;
				}

				.avtive_top_right {
					width: 25px;
					height: 15px;
					position: absolute;
					bottom: -15px;
					right: 0;
					z-index: 8;
					background: #0055e9;
					border-top-right-radius: 15px;
				}
				.avtive_top_bot_right {
					width: 25px;
					height: 15px;
					position: absolute;
					bottom: -15px;
					right: 0;
					background: #fff;
					z-index: 7;
				}
			}

			& > div {
				height: 90px;
				box-sizing: border-box;
				padding: 14px 0;
				display: flex;
				justify-content: center;
				align-items: center;
				border-top-left-radius: 20px;
				border-bottom-left-radius: 20px;
				// margin: 0 20px;
				margin-left: 20px;
				position: relative;
				// overflow: hidden;
				.active_aci {
					width: 5px;
					height: 100px;
					background: #000;
					position: absolute;
					top: 0;
					right: 0;
				}
			}

			& > div > i {
				display: flex;
				font-size: 32px;
				float: left;
				color: #fff;
			}

			.zw_tive {
				display: inline-block;
				width: 25px;
				height: 20px;
			}

			& > div > i:first-child {
				margin-right: 8px;
			}

			& > div > i:nth-child(3) {
				font-size: 16px;
				margin-top: 8px;
				transition: all 0.2s;
			}

			& > div > i:nth-child(4) {
				display: none;
			}

			& > div > span {
				font-size: 16px;
				color: #fff;
				float: left;
			}

			& > div > .el-icon-arrow-right {
				margin-bottom: 7px;
				margin-left: 10px;
				margin-right: 0;
			}

			& > div.active {
				// background: rgb(78, 125, 248);
				background: #fff;
				i {
					color: #0055e9;
				}
				span {
					color: #0055e9;
				}
			}
		}

		div:hover {
			& > i,
			& > span {
				color: #fff;
			}
		}

		li.active {
			position: relative;

			& > div {
				border-left: 4px solid #1e9fff;
			}

			& > div > i,
			& > div > span {
				color: #fff;
			}

			& > div > i:nth-child(4) {
				display: inline-block;
				position: absolute;
				right: -19px;
				font-size: 26px;
			}
		}
	}

	.ss_icon {
		position: fixed;
		bottom: 50px;
		left: 150px;
		color: #fff;
		i {
			font-size: 20px;
			&:hover {
				cursor: pointer;
			}
		}
	}

	ul.level-2 {
		li {
			&:first-child {
				margin-top: 15px;
			}
			& > div {
				overflow: hidden;
				height: auto;
				border-top-left-radius: 15px;
				border-bottom-left-radius: 15px;
			}

			& > div > i:nth-child(1) {
				font-size: 16px;
				width: 18px;
				height: 18px;
				background: #4c5156;
				font-style: normal;
				border-radius: 50%;
				text-align: center;
				line-height: 16px;
				margin: 4px 12px 0 26px;
			}

			& > div > i.active {
				background: #4e7df8;
				color: #fff;
			}

			& > div > i:nth-child(3) {
				font-size: 16px;
				margin-top: 6px;
				// margin-left: 4px;
			}

			& > div > span {
				font-size: 16px;
				margin-top: 2px;
			}
		}
	}

	ul.level-3 {
		li {
			& > div.active {
				// background: linear-gradient(
				//   90deg,
				//   rgba(35, 41, 48, 0.7) 0%,
				//   rgba(90, 95, 98, 0.7) 51%,
				//   rgba(35, 41, 48, 0.7) 100%
				// );
				background: #fff;
				& > span {
					color: #fff;
				}
			}

			& > div > i {
				font-size: 20px;
				display: none;
			}

			& > div > i {
				margin: 0 15px 0 28px;
			}

			& > div > i:nth-child(3) {
				font-size: 18px;
			}

			& > div > span {
				font-size: 14px;
				margin: 0 25px 0 70px;
			}
		}
	}
}

.left-level-2 {
	float: left;
	width: 240px;
	height: 100%;
	font-family: 'Helvetica Neue', 'Helvetica', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
	color: #000;
	background: #fff;

	&::-webkit-scrollbar {
		width: 0 !important;
	}

	box-shadow: 2px 0px 4px 0px rgba(215, 215, 215, 0.5);

	// padding: 0 20px;
	.el-input.el-input--prefix {
		// margin-left: -22px;
		margin-bottom: 10px;

		input {
			border: 0;
		}
	}

	ul {
		width: 240px;
		overflow: hidden;
		transition: all 0.5s;
		margin-left: -4px;
		border: 0;

		li {
			color: #626262;
			// height: 46px;
			width: 100%;
			box-sizing: border-box;
			// padding: 12px 0;
			cursor: pointer;

			& > div {
				// height: 56px;
				box-sizing: border-box;
				padding: 14px 0;
				overflow: hidden;
			}

			& > div > i {
				font-size: 16px;
				margin: 8px 18px 0 30px;
				float: left;
				color: #626262;
			}

			& > div > i:nth-child(3) {
				font-size: 16px;
				margin-top: 6px;
				transition: all 0.2s;
			}

			& > div > i:nth-child(4) {
				display: none;
			}

			& > div > span {
				font-size: 16px;
				color: #626262;
				float: left;
				margin-top: 4px;
			}
		}
	}

	.el-tree.filter-tree {
		padding: 0 20px;
	}
}

.ModifyDoalog {
	margin-top: -20px;
	padding: 0 0 0 20px;

	& > p {
		font-size: 20px;
		color: #3b3b3b;
		text-align: center;
		margin-bottom: 20px;
	}
}

.content {
	width: calc(100vw - 190px);
	height: 100%;
	// overflow: hidden;
	box-sizing: border-box;
	padding: 10px 0 0 10px;

	// width: 1320px;
	& > div.headBreadcrumb {
		height: 90px;
		// padding: 20px 0 0 14px;
		line-height: 90px;
		margin-right: -20px;
		display: flex;
		align-items: center;
		p {
			margin-left: 20px;
			font-size: 28px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 42px;
			span {
				font-size: 20px;
			}
		}
		// box-shadow: 0px 2px 4px 0px rgba(215, 215, 215, 0.5);

		& > div:nth-child(1) {
			font-size: 14px;
		}

		.login_name {
			margin-left: auto;
			margin-right: 40px;
			font-size: 16px;
			color: #333333;
			a {
				margin-right: 30px;
				color: rgb(40, 77, 241);
			}
		}
	}
}

// .content.hide{
//  width: 1200px !important;
// }

// .contentViewWrap {
//     & > div {
//         padding: 18px 0 20px 14px;
//     }
// }
.contentViewWrap {
	position: relative;
	height: calc(100% - 100px);
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	/deep/ .home_table {
		margin: 0 10px 10px;
		background: #fff;
		border-radius: 5px;
		padding-left: 25px;
		// .el-tabs__header{
		//   margin-bottom: 0;
		//   .el-tabs__nav-wrap::after{
		//     background: #fff;
		//     width: 90%
		//   }
		//   .el-tabs__nav{
		//     height: 55px;
		//     line-height: 55px;
		//     // margin: 0 1.125rem !important;
		//     font-size: 14px;
		//     padding-left: 25px;
		//   }
		//   .el-tabs__item{
		//     font-size: 16px;
		//     padding: 0 25px;
		//   }
		//   .is-active{
		//     color: #0055E9;
		//   }
		//   .el-tabs__active-bar{
		//     background-color: #0055E9;
		//   }
		// }
		.el-tabs__header {
			margin-bottom: 0;
			.el-tabs__nav-wrap::after {
				background: #fff;
				margin-left: 10px;
				width: 90%;
			}
			.el-tabs__nav {
				// height: 55px;
				line-height: 55px;
				margin-left: 25px;
				font-size: 14px;
			}
			.el-tabs__item {
				height: 100% !important;
				font-size: 16px;
				// padding-left: 25px;
				box-sizing: border-box;
				// padding-right: 25px;
				&:hover {
					color: #0055e9;
				}
			}
			.is-active {
				color: #0055e9;
			}
			.el-tabs__active-bar {
				background-color: #0055e9;
			}
		}
	}
	// .el-tabs__item{
	//   height: 0px !important;
	// }

	.avtive_epp {
		position: relative;
	}
	.level-2 {
		width: 220px;
		background: #fff;
		margin-right: 10px;
		padding-top: 30px;

		li {
			height: 50px;
			line-height: 50px;
			text-align: left;
			padding-left: 20px;
			z-index: 11;
			color: #666666;
			&:hover {
				cursor: pointer;
			}

			.av_active {
				background: #d7deee;
				span {
					color: #0055e9;
				}
				i {
					color: #0055e9 !important;
				}
			}
			& > div {
				padding: 0 20px;
				border-top-left-radius: 20px;
				border-bottom-left-radius: 20px;
			}

			& > div > span {
				font-size: 16px;
				margin-top: 2px;
			}
		}
	}

	.level-3 {
		overflow: hidden;
		li {
			padding: 0;
			padding-left: 10px;
			& > div {
				border-radius: 0px;
				border-top-left-radius: 20px;
				border-bottom-left-radius: 20px;
			}
			& > div > span {
				font-size: 14px;
			}
		}
		li:first-child {
			margin-top: 12px;
		}
		li:last-child {
			margin-bottom: 12px;
		}
	}

	.avtive_tiv {
		position: absolute;
		top: 0px;
		right: 0;
		height: 50px;
		width: 5px;
		background: #d7deee;
		display: block;
		z-index: 9;
		.avtive_top {
			width: 20px;
			height: 20px;
			position: absolute;
			top: -20px;
			right: 0;
			z-index: 8;
			background: #fff;
			border-bottom-right-radius: 20px;
		}
		.avtive_top_bot {
			width: 20px;
			height: 20px;
			position: absolute;
			top: -20px;
			right: 0;
			background: #d7deee;
			z-index: 7;
		}

		.avtive_top_right {
			width: 20px;
			height: 20px;
			position: absolute;
			bottom: -20px;
			right: 0;
			z-index: 8;
			background: #fff;
			border-top-right-radius: 20px;
		}
		.avtive_top_bot_right {
			width: 20px;
			height: 20px;
			position: absolute;
			bottom: -20px;
			right: 0;
			background: #d7deee;
			z-index: 7;
		}
	}
}
</style>
<style lang="scss">
.content-flex-pages {
	border-radius: 5px;
	overflow: hidden;
}
/deep/ .el-dropdown-menu {
	top: 70px;
	left: 1400px;
}

.top-breadcrumb {
	.el-breadcrumb__inner {
		font-size: 16px;
		// opacity: .7;
	}

	// .el-breadcrumb__inner:last-child{
	// opacity: 1;
	// }
	.el-breadcrumb {
		.el-breadcrumb__item {
			.el-breadcrumb__inner {
				opacity: 0.8;
			}
		}

		.el-breadcrumb__item:last-child {
			.el-breadcrumb__inner {
				opacity: 1;
			}
		}
	}

	.el-breadcrumb__separator {
		color: #606266 !important;
	}

	.el-breadcrumb {
		line-height: 50px;
	}
}

.headBreadcrumb {
	margin: -10px -10px 0 -10px;
}

.head-notice {
	width: 62px !important;
	margin-top: -6px !important;
	padding-right: 10px;
	border-right: 0 !important;

	i {
		font-size: 30px !important;
	}

	.el-badge__content {
		top: 12px;
		width: 14px;
		height: 14px;
		line-height: 14px;
		font-size: 10px;
		padding: 0;
		left: 7px;
	}
}

.left-level-2 {
	.el-input.el-input--prefix {
		input {
			border: 0;
			background: #f4f6f9;
			padding-left: 40px;
		}

		.el-input__prefix {
			left: 15px;

			.el-input__icon.iconfont .iconsousuo {
				font-size: 16px;
			}
		}
	}
}

.level2Tree {
	overflow: auto;

	.iconfont {
		font-size: 16px;
	}

	.el-tree-node {
		margin: 8px 0;
	}
}

.left-level-2 .level2Tree .el-tree-node.is-current > .el-tree-node__content {
	background: #e8f0f6 !important;
}

.left-level-2 .level2Tree > .el-tree-node.is-checked > .el-tree-node__content {
	background: #e8f0f6;
}

// .left-level-2 .el-tree-node.is-focusable.is-checked[aria-checked="true"] {
//     background: #E8F0F6;
// }

// .left-level-2 .el-tree-node.is-expanded.is-focusable.is-checked {
//     background: transparent;
// }

.level2Tree {
	.el-tree-node__expand-icon {
		font-size: 14px;
	}

	.expanded {
		transform: rotate(0deg) !important;
	}

	.expanded::before {
		content: '\e63a';
	}
}
.el-cascader {
	.el-input .el-icon-arrow-down {
		font-size: 18px;
	}
	.el-icon-arrow-down:before {
		content: '\e790';
	}
}

.el-select {
	.el-icon-arrow-up:before {
		content: '\e78f';
	}
	.el-input .el-select__caret {
		font-size: 18px;
	}
}
</style>
